import * as React from "react"
import ButtonLink from "../components/ButtonLink"
import Grid from "../components/grid/Grid"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

// markup
const NotFoundPage = () => {
  return (
    <main>
      <SEO title="404 - Page Not Found" />
      <Layout>
        <Grid>
          <div className=" grid col-span-full md:col-start-2 md:col-end-12 justify-items-start gap-y-8">
            <h1>404 Not found</h1>
            <p>Sorry, the page you are looking for is not here.</p>
            <ButtonLink to="/" text="Home Page" />
          </div>
        </Grid>
      </Layout>
    </main>
  )
}

export default NotFoundPage
